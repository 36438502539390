@font-face {
    font-family: bebas-neue-regular;
    src: url(./assets/fonts/BebasNeueRegular.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: sans-serif-demibold-flf;
    src: url(./assets/fonts/SansSerifFLF-Demibold.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: bebas-neue-bold;
    src: url(./assets/fonts/BebasNeueBold.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: sfui-text-regular;
    src: url(./assets/fonts/SFUITextRegular.otf) format("opentype");
    font-display: block;
}
@font-face {
    font-family: barlow-regular;
    src: url(./assets/fonts/Barlow-Regular.ttf) format("truetype");
    font-display: block;
}
@font-face {
    font-family: exo-regular;
    src: url(./assets/fonts/Exo-Regular.ttf) format("truetype");
    font-display: block;
}

:root {
    --artiox-blue: #50bfd2;  /*  RIP :'(  */

    /*v2*/

    /*main colors*/
    --main-color-artiox-purple-100: #F3F0FE;
    --main-color-artiox-purple-200: #ECE8FD;
    --main-color-artiox-purple-300: #D8D0FA;
    --main-color-artiox-purple-base: #8266F0;
    --main-color-artiox-purple-400: #755CD8;
    --main-color-artiox-purple-500: #6852C0;

    /*background color*/
    --background-color-artiox-background: #FFFFFF;

    /*white/black*/
    --text-color-artiox-black: #131515;
    --text-color-artiox-white: #FFFFFF;
    --text-color-artiox-light-black: #37324D;
    --text-color-artiox-grey: #6F6F6F;

    /*secondary colors*/
    /*red*/
    --secondary-colors-artiox-red-100: #FDEBEC;
    --secondary-colors-artiox-red-200: #FCE1E3;
    --secondary-colors-artiox-red-300: #F8C2C5;
    --secondary-colors-artiox-red-base: #EA3943;
    --secondary-colors-artiox-red-400: #D3333C;
    --secondary-colors-artiox-red-500: #BB2E36;

    /*green*/
    --secondary-colors-artiox-green-100: #F0F8F0;
    --secondary-colors-artiox-green-200: #E8F5E9;
    --secondary-colors-artiox-green-300: #D0EAD1;
    --secondary-colors-artiox-green-base: #66BB6A;
    --secondary-colors-artiox-green-400: #5CA85F;
    --secondary-colors-artiox-green-500: #529655;

    /*yellow*/
    --secondary-colors-artiox-yellow-100: #FFF9E6;
    --secondary-colors-artiox-yellow-200: #FFF6D9;
    --secondary-colors-artiox-yellow-300: #E6B000;
    --secondary-colors-artiox-yellow-base: #FFC300;
    --secondary-colors-artiox-yellow-400: #BF9200;
    --secondary-colors-artiox-yellow-500: #997500;

    /*blue*/
    --secondary-colors-artiox-blue-100: #ECEFFD;
    --secondary-colors-artiox-blue-200: #E3E7FC;
    --secondary-colors-artiox-blue-300: #C5CEFA;
    --secondary-colors-artiox-blue-base: #4361EE;
    --secondary-colors-artiox-blue-400: #3C57D6;
    --secondary-colors-artiox-blue-500: #364EBE;

    /*gray*/
    --secondary-colors-artiox-gray-100: #FCFCFC;
    --secondary-colors-artiox-gray-200: #FBFBFB;
    --secondary-colors-artiox-gray-300: #F6F6F6;
    --secondary-colors-artiox-gray-base: #E2E2E2;
    --secondary-colors-artiox-gray-400: #CBCBCB;
    --secondary-colors-artiox-gray-500: #B5B5B5;

    /*white*/
    --secondary-colors-artiox-white-100: #E6E6E6;
    --secondary-colors-artiox-white-200: #CCCCCC;

}


.btn-native {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: white;
    font-family: Roboto, sans-serif;
    white-space: nowrap;
}

.btn-simple {
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    letter-spacing: 2px;
    color: white;
    cursor: pointer;
    border: none;
    background-color: rgba(255, 255, 255, 0);
}

.btn-simple:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.dropdown-native-button {
}

.dropdown-native-button:hover .dropdown-native-menu {
    display: flex;
}

.dropdown-native-button:hover .dropdown-native-menu-black {
    display: flex;
}

.dropdown-native-button:hover .dropdown-native-menu-white {
    display: flex;
}

.dropdown-native-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 100;
}

.dropdown-native-menu-black {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #1e1f25;
    z-index: 100;
}

.dropdown-native-item-black {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100px;
    height: 40px;
    font-family: Roboto, sans-serif;
    border-radius: 4px;
    margin: 4px;
}

.dropdown-native-item-black:hover {
    box-shadow: 0 2px 2px 1px rgba(27, 28, 36, 0.4);
    background-color: rgba(52, 54, 64, 1);
}

.dropdown-native-item-black:active {
    box-shadow: inset 0 2px 8px 1px rgba(3, 3, 4, 0.8);
}

.dropdown-native-menu-white {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 180px;
    overflow: scroll;
    box-shadow: 2px 2px 4px 0 #d5d8e2;
    background-color: #ffffff;
    z-index: 100;
}

.dropdown-native-item-white {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.2;
    color: #595e7e;
    flex-shrink: 0;

}

.dropdown-native-item-white:hover {
    background-color: var(--secondary-colors-artiox-gray-100);
    border-radius: 4px;
}


.navbar-native-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: calc(100% - 56px);
    top: 0;
    right: 0;
    background-color: rgba(36, 37, 42, 1);
    z-index: 100;
}

.navbar-native-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 180px;
    height: 50px;
    font-family: Roboto, sans-serif;
    border-radius: 4px;
    margin: 4px;
}

.navbar-native-item:active {
    box-shadow: inset 0 2px 8px 1px rgba(3, 3, 4, 0.8);
}

.input-heading {
    height: 40px;
    width: 90%;
    margin: 2px auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    color: #282b34;
}

.input-field {
    height: 40px;
    width: 90%;
    margin: 2px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #c7c9d2;
    border-radius: 8px;
    background-color: rgba(234, 236, 241, 0.5);
    box-shadow: 2px 2px 20px 3px rgba(233, 237, 246, 0.70), inset 1px 1px 4px rgba(197, 198, 203, 0.5);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 26px;
    color: #4a4e70;
}

.input-field::placeholder {
    color: #d7d9e5;
}

.input-field-required {
    border: 1px solid #e02057 !important;
}

.input-field-underline-required {
    border-bottom: 1px solid #e02057 !important;
}

.checkbox-field {
    border: 1px solid #adb8c0;
}

.checkbox-field-required {
    border: 1px solid red !important;
}

.warning {
    display: flex;
    align-items: center;
    color: #e02057;
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 16px;
}

.warning::before {
    height: 24px;
    margin-right: 5px;
    bottom: 1px;
    content: url("https://api.iconify.design/jam:triangle-danger.svg?color=%23e02057&height=24");
}

.success {
    color: var(--artiox-blue);
    font-family: Roboto, sans-serif;
    font-style: italic;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
}

.success::before {
    height: 24px;
    margin-right: 5px;
    bottom: 1px;
    content: url('https://api.iconify.design/bx:bx-check.svg?color=%234bc4d6&height=24');
}

.z-index-inherit * {
    z-index: inherit;
}

.btn-white {
    width: 150px;
    height: 40px;
    box-shadow: 1px 1px 8px 1px rgba(172, 188, 217, 0.4);
    border-radius: 30px;
    border: 1px solid white;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
}

.btn-white:hover {
    box-shadow: 0 0 18px 3px rgba(73, 158, 255, 0.8), inset 0 0 4px 3px rgba(130, 226, 255, 0.9);
    background-image: radial-gradient(circle, #53a0f1 0%, #45e2ff 100%);
    border: none;
}

.btn-white:active {
    box-shadow: 0 0 10px 2px rgba(50, 146, 255, 0.6), inset 0 1px 5px 3px rgba(75, 103, 255, 0.5);
    background-image: radial-gradient(circle, #53a0f1 0%, #45e2ff 100%);
    text-shadow: 1px 1px 3px rgba(40, 43, 52, 1);
    border: none;
}

.citation {
    line-height: 1px;
    vertical-align: super;
    font-size: 12px;
}

.pre-sale-buy-button {
    border-radius: 8px;
    transition: 0.1s ease-in-out;
    box-shadow: 1px 1px 4px 0 rgba(244, 245, 249, 0.28);
    background-image: linear-gradient(to bottom, #f3f3f3, #f0f0f1 15%, #cacdd5);
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.pre-sale-buy-button:hover {
    transform: translateY(-1px);
}

.pre-sale-buy-button:active {
    box-shadow: 2px 2px 7px -2px rgba(195, 220, 223, 0.57), inset 2px 2px 12px 1px rgba(55, 62, 91, 0.4);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    transform: translateY(1px);
}

.buy-button {
    border-radius: 8px;
    transition: 0.1s ease-in-out;
    background: #66BB6A;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
}

.buy-button:hover {
    background: #5CA85F;
}

.sell-button {
    border-radius: 8px;
    transition: 0.1s;
    background: #EA3943;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
}

.sell-button:hover {
    background: #D3333C;
}

.toggle-span span {
    display: none;
}

.toggle-a a {
    display: none;
}

.input-field-trade {
    height: 30px;
    width: 90%;
    margin: 2px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    border: none;
    background-color: #f4f5f9;
    font-family: barlow-regular, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    color: #404352;
}

.input-field-trade::placeholder {
    padding: 0 15px;
    color: #9da5b7;
}

.input-field-white {
    height: 40px;
    width: 90%;
    margin: 2px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: solid 1px var(--secondary-colors-artiox-gray-base);
    background-color: #fcfdff !important;
    font-family: barlow-regular, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #4a4e70;
}

.input-field-white::placeholder {
    color: #d7d9e5;
}

.input-field-black {
    height: 40px;
    width: 90%;
    margin: 2px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: solid 1px #c0c4da;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 26px;
    color: #d7d9e5;
}

.input-field-black::placeholder {
    color: #4a4e70;
}

@media screen and (min-width: 768px ) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;

    }

    ::-webkit-scrollbar:vertical {
        width: 6px;

    }

    ::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        /*background-color: rgba(199, 203, 233, 0.6);*/
        background-color: #b5b5b5;
        border-radius: 4px;


    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }
}

.sign-up-login-button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 26px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 2px rgba(38, 198, 255, 0.20);
    background-image: radial-gradient(circle 225px at 91.76% 84%, #62e8ff 0%, #7ab7ff 100%);

}

.sign-up-login-button:hover {
    box-shadow: 1px 1px 14px 2px rgba(8, 91, 129, 0.226), inset 0 0 14px 4px rgba(199, 242, 255, 0.26);
    background-image: radial-gradient(circle 175px at 91.76% 22%, #62fbff 0%, #7ab7ff 100%);
}

.sign-up-login-button:active {
    text-shadow: 0 2px 4px rgba(26, 22, 86, 0.57);
    box-shadow: 1px 1px 12px 3px rgba(38, 198, 255, 0.1), inset 0 1px 5px 3px rgba(75, 103, 255, 0.5);
    background-image: radial-gradient(circle, #53a0f1 0%, #45e2ff 100%);
}

div[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

svg[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

span[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

p[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a[type=button] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.a-color {
    color: #c0c4da !important;
}
.sign-up-agreements-color{
    color: var(--main-color-artiox-purple-base) !important;
}
.sign-up-agreements-color:hover,
.sign-up-agreements-color:focus{
    color: var(--main-color-artiox-purple-500) !important;
}

.clarification-text {
    color: white;
    text-decoration: underline;
    cursor: pointer
}

.clarification-text-black {
    color: black;
    cursor: pointer
}

.checkbox-text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: left;
    color: #f4f5f9;
}

.checkbox-text-black {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: left;
    color: #404352;
}

input[type=checkbox] {
    height: 12px;
    min-width: 12px;
    padding: 0;
    margin-top: 4px;
    appearance: none;
    box-shadow: 1px 1px 1px 0 rgba(64, 67, 82, 0.3);
    background: white;
}

input[type=checkbox]:checked {
    background-color: var(--artiox-blue);
    content: url('./assets/images/white_tick_icon.svg#white_tick_icon');
    box-shadow: none;
}


.password-eye {
    position: absolute;
    right: 5%;
    height: 20px;
    width: 20px;
    margin: 10px;
}

.dropdown-icon {
    position: absolute;
    right: 0;
    height: 24px;
    width: 24px;
    margin: 3px;
}

.cancel-icon {
    position: absolute;
    right: 5%;
    height: 24px;
    width: 24px;
    margin: 10px;
}

.btn-account-navigator {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1.6px;
    color: #595e7e;
}

.btn-account-navigator-active {
    color: var(--artiox-blue);
}

.navigator-active {
    color: var(--artiox-blue);
    font-weight: 500;
}

.references {
    margin: 40px 4px 4px -30px;
    font-size: 12px;
    line-height: 1.2;
    color: #b1b3c5;
    font-style: italic;
}

.input-field-underline {
    height: 30px;
    width: 90%;
    margin: 2px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: solid 1px #abafc7;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 26px;
    color: #4a4e70;
    outline: 0 none;
    background-color: transparent;
}

.input-field-underline::placeholder {
    /*color: #d7d9e5;*/
}

.input-field-underline:focus {
    border: none;
    border-bottom: 1px solid var(--artiox-blue) !important;
}


.gray-underline {
    color: #b1b3c5;
    text-decoration: underline;
}

input[type=file]::-webkit-file-upload-button {
    display: none;
}

/* below is from bootstrap4 */
.mr-0 {
    margin-right: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mr-1 {
    margin-right: .25rem !important
}

.ml-1 {
    margin-left: .25rem !important
}

.mr-2 {
    margin-right: .5rem !important
}

.ml-2 {
    margin-left: .5rem !important
}

.mr-3 {
    margin-right: 1rem !important
}

.ml-3 {
    margin-left: 1rem !important
}

.mr-4 {
    margin-right: 1.5rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mr-5 {
    margin-right: 3rem !important
}

.ml-5 {
    margin-left: 3rem !important
}

.pr-0 {
    padding-right: 0 !important
}

.pl-0 {
    padding-left: 0 !important
}

.pr-1 {
    padding-right: .25rem !important
}

.pl-1 {
    padding-left: .25rem !important
}

.pr-2 {
    padding-right: .5rem !important
}

.pl-2 {
    padding-left: .5rem !important
}

.pr-3 {
    padding-right: 1rem !important
}

.pl-3 {
    padding-left: 1rem !important
}

.pr-4 {
    padding-right: 1.5rem !important
}

.pl-4 {
    padding-left: 1.5rem !important
}

.pr-5 {
    padding-right: 3rem !important
}

.pl-5 {
    padding-left: 3rem !important
}

.mr-n1 {
    margin-right: -.25rem !important
}

.ml-n1 {
    margin-left: -.25rem !important
}

.mr-n2 {
    margin-right: -.5rem !important
}

.ml-n2 {
    margin-left: -.5rem !important
}

.mr-n3 {
    margin-right: -1rem !important
}

.ml-n3 {
    margin-left: -1rem !important
}

.mr-n4 {
    margin-right: -1.5rem !important
}

.ml-n4 {
    margin-left: -1.5rem !important
}

.mr-n5 {
    margin-right: -3rem !important
}

.ml-n5 {
    margin-left: -3rem !important
}

.mr-auto {
    margin-right: auto !important
}

.ml-auto {
    margin-left: auto !important
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.text-info {
    color: var(--artiox-blue) !important
}

a.text-info:focus, a.text-info:hover {
    color: #0f6674 !important
}

/* above is from bootstrap4 */

img {
    object-fit: contain;
}

.w-33 {
    width: 33%;
}

.w-66 {
    width: 66%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.cursor-pointer{
    cursor: pointer;
}

.text-info-arrow {
    color: var(--artiox-blue) !important;
}

input:focus {
    outline: none;
    border: 1px solid var(--artiox-blue);
}

/*.trade-slider {*/
/*    appearance: none;*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    width: 75%;*/
/*    height: 8px;*/
/*    margin: 12px 0;*/
/*    border-radius: 4px;*/
/*    background: #757E8E;*/
/*    outline: none;*/
/*    opacity: 0.7;*/
/*    transition: opacity .2s;*/
/*    -webkit-transition: .2s;*/
/*    cursor: pointer;*/
/*}*/

/*.trade-slider:focus {*/
/*    outline: none;*/
/*    border: none;*/
/*}*/

/*.trade-slider:hover {*/
/*    opacity: 0.9;*/
/*}*/

/*.trade-slider::-webkit-slider-thumb {*/
/*    appearance: none;*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    border: 1px solid #fff;*/
/*    border-radius: 2px;*/
/*    cursor: pointer;*/
/*    transform: rotate(45deg);*/
/*}*/

/*.sliderBuy::-webkit-slider-thumb {*/
/*    background: #0AD5A0;*/
/*}*/

/*.sliderSell::-webkit-slider-thumb {*/
/*    background: #E30E4E;*/
/*}*/

/*.trade-slider::-moz-range-thumb {*/
/*    appearance: none;*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    border: 1px solid #fff;*/
/*    border-radius: 2px;*/
/*    cursor: pointer;*/
/*    transform: rotate(45deg);*/
/*}*/

/*.sliderBuy::-moz-range-thumb {*/
/*    margin: 12px 0;*/
/*    background: #0AD5A0;*/
/*}*/

/*.sliderSell::-moz-range-thumb {*/
/*    margin: 12px 0;*/
/*    background: #E30E4E;*/
/*}*/

/*.sliderMarker {*/
/*    cursor: pointer;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    margin: 8px 0;*/
/*    border: 1px solid #fff;*/
/*    border-radius: 2px;*/
/*    background: #757E8E;*/
/*    transform: rotate(45deg);*/
/*    opacity: 1;*/
/*}*/


.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.text-decoration-underline-lower {
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
}

.row-separator:nth-child(odd) {
    background-color: #f4f5f9;
}

.row-separator-dark:nth-child(even) {
    background-color: #d9dbe4;
}

.row-separator-dark:nth-child(odd) {
    background-color: #c0c4da;
}

.header1{
    font-size: 64px;
    font-style: normal;
    line-height: 80px;
}
.header2{
    font-size: 52px;
    font-style: normal;
    line-height: 64px;
}
.header3{
    font-size: 42px;
    font-style: normal;
    line-height: 52px;
}
.header4{
    font-size: 33px;
    font-style: normal;
    line-height: 40px;
}
.header5{
    font-size: 27px;
    font-style: normal;
    line-height: 36px
}
.header6{
    font-size: 21px;
    font-style: normal;
    line-height: 28px;
}
.subtitle1{
    font-size: 17px;
    font-style: normal;
    line-height: 24px;
}

.subtitle2{
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
}

.body0{
    font-size: 21px;
    font-style: normal;
    line-height: 28px;
}
.body1{
    font-size: 17px;
    font-style: normal;
    line-height: 24px;
}

.body2{
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
}

.caption{
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
}

.overline{
    font-size: 10px;
    font-style: normal;
    line-height: 12px;
}

@media only screen and (max-width: 767px) {
    .header1{
        font-size: 42px;
        font-style: normal;
        line-height: 52px;
    }
    .header2{
        font-size: 35px;
        font-style: normal;
        line-height: 42px;
    }
    .header3{
        font-size: 29px;
        font-style: normal;
        line-height: 36px;
    }
    .header4{
        font-size: 24px;
        font-style: normal;
        line-height: 28px;
    }
    .header5{
        font-size: 20px;
        font-style: normal;
        line-height: 24px
    }
    .subtitle1{
        font-size: 17px;
        font-style: normal;
        line-height: 20px;
    }

    .subtitle2{
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
    }

    .body0{
        font-size: 24px;
        font-style: normal;
        line-height: 28px;
    }
    .body1{
        font-size: 21px;
        font-style: normal;
        line-height: 24px;
    }

    .body2{
        font-size: 17px;
        font-style: normal;
        line-height: 20px;
    }

    .caption{
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
    }

    .overline{
        font-size: 11px;
        font-style: normal;
        line-height: 12px;
    }
}
:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).exo {
    font-family: exo-regular, sans-serif;
}
:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).barlow {
    font-family: barlow-regular, sans-serif;
}
:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).light {
    font-weight: 300;
}

:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).regular {
    font-weight: 400;
}

:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).medium {
    font-weight: 500;
}

:is(.header1, .header2, .header3, .header4, .header5, .header6, .subtitle1, .subtitle2, .body0, .body1, .body2, .caption, .overline).bold {
    font-weight: 700;
}

.free-green {
    color: #caff8a;
    font-weight: 700;
}
html, body{min-height:100%;}
body{height:100vh;}
